<template>
  <b-container>
    <b-row>
      <!-- Content Left -->
      <b-col
        class="content-header-left px-0 mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title">
              Service Form for {{ serviceForm.stringID }}
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <!-- Content Left -->
      <b-col
        ref="sticky_sidebar"
        lg="3"
        class="top-info-right"
      >
        <div
          class="user_block ac_type"
          style="float: none;"
        >
          <div class="user_avatar" />
          <div class="user_info">
            <h6>Status</h6>
            <h5 class="">
              {{ serviceForm.formStatus }}
            </h5>
          </div>
        </div>
        <b-card
          v-if="serviceForm.formStatus == 'Signing'"
          class="mt-2 service-forms-card mb-0"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="FeatherIcon"
                class=""
                size="20"
                style="color: #fff;"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              <span class="step-class">signing required</span>
              Start signing
            </h3>
          </template>
          <b-card-text
            class="mb-2"
          >
            <span>This form requires 2 signatures from:</span>
          </b-card-text>

          <app-timeline class="sign-list">
            <app-timeline-item
              v-if="serviceForm.customerSign"
              title="Purchaser’s / Next of Kin’s Signatory & Date"
              icon="CheckIcon"
              variant="primary"
              class="signed"
            />
            <app-timeline-item
              v-else
              title="Purchaser’s / Next of Kin’s Signatory & Date"
              icon="FeatherIcon"
              variant="primary"
            />
            <app-timeline-item
              v-if="serviceForm.staffSign"
              title="Service Staff"
              icon="CheckIcon"
              variant="primary"
              class="signed"
            />
            <app-timeline-item
              v-else
              title="Service Staff"
              icon="FeatherIcon"
              variant="primary"
            />
          </app-timeline>

          <hr
            class="dividerHR"
            style="margin-top: 6px;"
          >

          <b-card-text
            class="mt-2"
          >
            <span>After submitted the signed form, the system will generate a PDF copy and email to customer.</span>
          </b-card-text>
        </b-card>
        <b-card
          v-if="serviceForm.formStatus == 'Ready to sign'"
          class="mt-2 service-forms-card"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="FeatherIcon"
                class=""
                size="20"
                style="color: #fff;"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              <span class="step-class">signing required</span>
              Ready to Sign?
            </h3>
          </template>
          <b-card-text
            class="mb-2"
          >
            <span>If you're prepared to proceed, please press the button to indicate your agreement to this form.</span>
          </b-card-text>

          <!-- Button: Send Invoice -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mb-75"
            block
            @click="startSignProcess"
          >
            <feather-icon
              icon="FeatherIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Sign now</span>
          </b-button>
        </b-card>
        <b-card
          v-if="serviceForm.formStatus == 'Signed and Sent'"
          class="mt-2 mb-0 service-forms-card"
        >
          <b-card-text
            class="mb-2"
          >
            <span>This form requires 2 signatures from:</span>
          </b-card-text>

          <app-timeline class="sign-list">
            <app-timeline-item
              title="Purchaser’s / Next of Kin’s Signatory & Date"
              icon="CheckIcon"
              variant="primary"
              class="signed"
            />
            <app-timeline-item
              title="Service Staff"
              icon="CheckIcon"
              variant="primary"
              class="signed"
            />
          </app-timeline>

          <hr class="dividerHR">

          <b-card-text
            class="mt-2"
          >
            <b-button
              variant="outline-primary"
              class="w-100"
              @click="resendSignedFormModal"
            >
              <feather-icon
                icon="SendIcon"
                class="mr-50"
                size="16"
              />
              <span>Resend signed form</span>
            </b-button>
          </b-card-text>
        </b-card>
        <div
          class="d-form__action-btn my-2"
        >
          <b-button
            variant="outline-primary"
            style="background: #FFF;"
            :to="{ name: 'operation-service-forms-show', params: { id: serviceForm._id || 0 } }"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              class="mr-50"
              size="16"
            />
            <span>Return to {{ serviceForm.stringID }}</span>
          </b-button>
          <b-button
            v-if="canViewThisAction('update', 'ServiceForm') || canViewThisAction('update-applicant', 'ServiceForm')
              || canViewThisAction('update-deceased', 'ServiceForm') || canViewThisAction('update-service-description', 'ServiceForm') || canViewThisAction('update-agent', 'ServiceForm')
              || canViewThisAction('update-attachment', 'ServiceForm') || canViewThisAction('update-duty-officer', 'ServiceForm') || canViewThisAction('update-status', 'ServiceForm')
              || canViewThisAction('update-payment', 'ServiceForm')"
            :to="{ name: 'operation-service-forms-edit', params: { id: serviceForm._id || 0 } }"
            variant="outline-primary"
            class="ml-auto"
            style="background: #FFF;"
          >
            <feather-icon
              icon="EditIcon"
              size="16"
              class="mt-50"
            />
          </b-button>
        </div>
      </b-col>
      <b-col
        lg="9"
        class="top-info-left"
      >
        <div class="ps-main-content-wrapper">
          <b-card class="m-0">
            <b-card-text>
              <div class="orn-form-header d-flex justify-content-between mb-75">
                <div class="d-form-logo_section" />
                <div class="d-form-no_section text-right">
                  <h5>
                    {{ serviceFormView.formNumber || 'NV-SG-NVC-FS01' }}
                  </h5>
                  <h5>
                    {{ serviceFormView.revision || 'REV: 1' }}
                  </h5>
                </div>
              </div>
              <div class="b-form-section-title my-2">
                <h3>
                  SERVICE FORM <span>礼仪服务预约表格</span>
                </h3>
              </div>
              <b-table-simple
                borderless
                responsive
                class="particulars_of_deceased d-none d-md-block ps-simple-table"
              >
                <b-tbody>
                  <b-tr>
                    <b-th colspan="2">
                      Applicant Details
                    </b-th>
                    <b-th colspan="2">
                      Particulars of Deceased
                    </b-th>
                  </b-tr>
                  <b-tr>
                    <b-td width="230px">
                      Applicant 报名者:
                    </b-td>
                    <b-td width="230px">
                      <span class="text-primary">{{ serviceForm.customerName }}</span>
                    </b-td>
                    <b-td width="230px">
                      Niche No. 福位编号:
                    </b-td>
                    <b-td>
                      <span class="text-primary">{{ serviceForm.deceasedNiche || '-' }}</span>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td width="230px">
                      Contact No. 联络号码:
                    </b-td>
                    <b-td width="230px">
                      <span class="text-primary">(65) {{ serviceForm.customerContact }}</span>
                    </b-td>
                    <b-td width="230px">
                      Pedestal No. 牌位编号:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.deceasedPedestal || '-' }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td width="230px">
                      Email 电邮:
                    </b-td>
                    <b-td width="230px">
                      <span class="text-primary">{{ serviceForm.customerEmail || '-' }}</span>
                    </b-td>
                    <b-td width="230px">
                      Anling Lot No. 安灵编号:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.deceasedAngling || '-' }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td />
                    <b-td />
                    <b-td width="230px">
                      Name of Deceased 先人名讳:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.deceasedName || '-' }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td />
                    <b-td />
                    <b-td width="230px">
                      Departed Date 逝世日期:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.departedDate ? dateFormat(serviceForm.departedDate) : '-' }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td />
                    <b-td />
                    <b-td width="230px">
                      Age 年龄:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.deceasedAge || '-' }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td />
                    <b-td />
                    <b-td width="230px">
                      Gender 性别:
                    </b-td>
                    <b-td><span class="text-primary text-capitalize">{{ serviceForm.deceasedGender || '-' }}</span></b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>

              <b-table-simple
                borderless
                responsive
                class="d-block d-md-none ps-simple-table"
              >
                <b-tbody>
                  <b-tr>
                    <b-th>
                      Applicant Details
                    </b-th>
                    <b-th />
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Applicant 报名者:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.customerName }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Contact No. 联络号码:
                    </b-td>
                    <b-td><span class="text-primary">(65) {{ serviceForm.customerContact }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Email 电邮:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.customerEmail || '-' }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-th>
                      Particulars of Deceased
                    </b-th>
                    <b-th />
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Niche No. 福位编号:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.deceasedNiche || '-' }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Pedestal No. 牌位编号:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.deceasedPedestal || '-' }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Anling Lot No. 安灵编号:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.deceasedAngling || '-' }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Name of Deceased 先人名讳:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.deceasedName || '-' }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Departed Date 逝世日期:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.departedDate ? dateFormat(serviceForm.departedDate) : '-' }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Age 年龄:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.deceasedAge || '-' }}</span></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Gender 性别:
                    </b-td>
                    <b-td><span class="text-primary">{{ serviceForm.deceasedGender || '-' }}</span></b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>

              <div class="ps-service-type-block">
                <h4 class="ps-service-type-title">
                  Service Type & Descriptions 服务类别和项目说明
                </h4>
                <div
                  v-for="(val, ind) in groupedCategory"
                  :key="ind"
                >
                  <h6 class="ps-service-type-name">
                    {{ val[0].workFlow.category.name }}
                  </h6>
                  <div
                    v-for="(request, requestIndex) in val"
                    :key="requestIndex"
                    class="ps-service-des-content-wrapper"
                  >
                    <div class="ps-service-des-block">
                      <div class="ps-service-des-checkbox-block">
                        <b-form-checkbox
                          v-model="checked"
                        >
                          <p>
                            {{ request.workFlow.name }}
                          </p>
                        </b-form-checkbox>
                      </div>
                      <div
                        v-if="!specialWorkflows.includes(request.workFlow._id)"
                        class="ps-service-des-content-block"
                      >
                        <b-table-simple
                          borderless
                          responsive
                          class="ps-simple-table"
                        >
                          <b-tbody>
                            <b-tr>
                              <b-td width="50%">
                                Location 地点:
                              </b-td>
                              <b-td><span class="text-primary">{{ request.location || '-' }}</span></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td width="50%">
                                Service Date and Time 预定服务日期与时间:
                              </b-td>
                              <b-td>
                                <span
                                  v-if="request.date"
                                  class="text-primary"
                                >
                                  {{ dateFormat(request.date) }}, {{ timeFormatFromDateString(request.date) }}
                                </span>
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-td width="50%">
                                Related File No. 相关文件号码:
                              </b-td>
                              <b-td><span class="text-primary">{{ request.fileNo || '-' }}</span></b-td>
                            </b-tr>
                            <b-tr class="border-bottom">
                              <b-td width="50%">
                                Remark 备注:
                              </b-td>
                              <b-td><span class="text-primary">{{ request.remarks || '-' }}</span></b-td>
                            </b-tr>
                          </b-tbody>
                          <b-tbody v-if="specialSDWorkflows.includes(request.workFlow._id)">
                            <b-tr
                              v-if="request.blessingQuantity"
                            >
                              <b-td
                                width="50%"
                                style="vertical-align: top; padding: 5px 0px !important;"
                              >
                                福
                              </b-td>
                              <b-td>
                                <span class="text-primary">x</span>
                                <span
                                  class="green-text"
                                >
                                  {{ request.blessingQuantity }}
                                </span>
                              </b-td>
                            </b-tr>
                            <b-tr
                              v-if="request.blessingQuantity"
                              :class="{ 'd-flex flex-column' : isMobile() }"
                            >
                              <b-td
                                width="50%"
                                style="vertical-align: top; padding: 5px 0px !important;"
                              >
                                Remarks
                              </b-td>
                              <b-td>
                                <span class="green-text">{{ request.blessingRemarks || '-' }}</span>
                              </b-td>
                            </b-tr>

                            <b-tr
                              v-if="request.lifeQuantity"
                              :class="{ 'd-flex flex-column' : isMobile() }"
                            >
                              <b-td
                                width="50%"
                                style="vertical-align: top; padding: 5px 0px !important;"
                              >
                                壽
                              </b-td>
                              <b-td>
                                <span class="text-primary">x</span>
                                <span
                                  class="green-text"
                                >
                                  {{ request.lifeQuantity }}
                                </span>
                              </b-td>
                            </b-tr>
                            <b-tr
                              v-if="request.lifeQuantity"
                              :class="{ 'd-flex flex-column' : isMobile() }"
                            >
                              <b-td
                                width="50%"
                                style="vertical-align: top; padding: 5px 0px !important;"
                              >
                                Remarks
                              </b-td>
                              <b-td>
                                <span class="green-text">{{ request.lifeRemarks || '-' }}</span>
                              </b-td>
                            </b-tr>
                          </b-tbody>
                          <b-tbody v-if="!specialSDWorkflows.includes(request.workFlow._id) && (request.showServiceDescription && request.showServiceDescription == true)">
                            <b-tr
                              v-for="(opt, key) in request.serviceDescriptionValues"
                              :key="key"
                            >
                              <b-td
                                v-if="(opt.children && opt.children.length) || opt.value"
                                width="50%"
                                style="vertical-align: top; padding: 5px 0px !important;"
                              >
                                {{ opt.key }}
                                <div>
                                  <span
                                    style="font-style: normal; font-weight: 400; font-size: 14px;"
                                  >
                                    {{ opt.text }}
                                  </span>
                                </div>
                              </b-td>
                              <b-td v-if="(opt.children && opt.children.length) || opt.value">
                                <div v-if="opt.children">
                                  <div
                                    v-for="(child, indx) in opt.children"
                                    :key="indx"
                                  >
                                    <span
                                      v-if="Array.isArray(child.value)"
                                      class="d-flex"
                                    >
                                      <span
                                        class="mr-50"
                                      >
                                        {{ child.key }}
                                      </span>
                                      <div v-if="child.value.length">
                                        <span
                                          v-for="(option, index) in child.value"
                                          :key="index"
                                        >
                                          {{ option }} <span v-if="index + 1 != child.value.length">, </span>
                                        </span>
                                      </div>
                                      <div v-else>
                                        <span>-</span>
                                      </div>
                                    </span>
                                    <span
                                      v-else
                                      class="d-flex"
                                    >
                                      <span
                                        class="mr-50"
                                      >
                                        {{ child.key }}
                                      </span>
                                      <div v-if="child.value">
                                        <span
                                          v-if="(child.type && ['increment', 'short-answer', 'long-answer'].includes(child.type))"
                                          class="green-text"
                                        >
                                          {{ child.value }}
                                        </span>
                                        <span v-else>{{ child.value }}</span>
                                      </div>
                                      <div v-else>
                                        <span>{{ child.type == 'title-and-text' ? '' : '-' }}</span>
                                      </div>
                                    </span>
                                  </div>
                                </div>
                                <div v-else>
                                  <span
                                    v-if="Array.isArray(opt.value)"
                                    class="d-flex"
                                  >
                                    <div v-if="opt.value.length">
                                      <span
                                        v-for="(option, index) in opt.value"
                                        :key="index"
                                      >
                                        {{ option }} <span v-if="index + 1 != opt.value.length">, </span>
                                      </span>
                                    </div>
                                    <div v-else>
                                      <span>-</span>
                                    </div>
                                  </span>
                                  <span
                                    v-else
                                    class="d-flex"
                                  >
                                    <div v-if="opt.value">
                                      <span
                                        v-if="(opt.type && ['increment', 'short-answer', 'long-answer'].includes(opt.type))"
                                        class="green-text"
                                      >
                                        {{ opt.value }}
                                      </span>
                                      <span v-else>{{ opt.value }}</span>
                                    </div>
                                    <div v-else>
                                      <span>{{ opt.type == 'title-and-text' ? '' : '-' }}</span>
                                    </div>
                                  </span>
                                </div>
                              </b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="border-bottom" />
                <b-row class="m-0">
                  <b-col
                    lg="4"
                    class="border-right p-0"
                  >
                    <div class="ps-notice-left-section">
                      <b-row class="m-0">
                        <b-col
                          lg="12"
                          class="p-0"
                        >
                          <p>Agent Name 代理姓名:</p>
                          <p class="ps-notice-field-value">
                            {{ serviceForm.agentName || '-' }}
                          </p>
                        </b-col>
                        <b-col
                          lg="12"
                          class="p-0"
                        >
                          <p>Agent Code 代理编号:</p>
                          <p class="ps-notice-field-value">
                            {{ serviceForm.agentCode || '-' }}
                          </p>
                        </b-col>
                        <b-col
                          lg="12"
                          class="p-0"
                        >
                          <p>Agent Mobile Number 代理联络号码:</p>
                          <p class="ps-notice-field-value">
                            {{ serviceForm.agentContact ? `(65)${serviceForm.agentContact}` : '-' }}
                          </p>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                  <b-col lg="8 p-0">
                    <div class="ps-notice-right-section">
                      <h5>Notice 备注</h5>
                      <ul>
                        <li>
                          Payment and Reservation is to be done 3 days in advance.
                          <br>
                          <span>预购是指三天前完成订货与缴付金额。</span>
                        </li>
                        <li>
                          Rental of premises is a must for chanting duration more than an hour.
                          <br>
                          <span>诵经时间一小时以上必需租用场地</span>
                        </li>
                        <li>
                          Rental of burning cage is required if there is a large amount of paper offerings. (eg. 8’ x 16’ of paper house or 12 offering chests and above.)
                          <br>
                          <span>若焚烧大量纸钱，衣箱（12个衣箱或超过）或纸屋（限制纸屋面积8尺 x 16尺）必需提出申请租用化宝架。</span>
                        </li>
                        <li>
                          Cheque shall be made payable to <b>“Mount Prajna Ltd”</b>
                          <br>
                          <span>支票上请注明交于</span>
                          <b>“Mount Prajna Ltd”</b>
                        </li>
                        <li>
                          All prices are inclusive GST.
                          <br>
                          <span>以上价格已含消费税。</span>
                        </li>
                      </ul>
                    </div>
                  </b-col>
                </b-row>
              </div>

              <div class="authorized-signature ps-authorized-signature mt-3">
                <b-row ref="signDiv">
                  <b-col md="6">
                    <p>
                      Purchaser / Authorized Representative Signatory & Date
                      <br>
                      <span>购买者/受委托领瓮人签名和日期</span>
                    </p>
                    <div
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-modal.customer-sign-modal
                      class="form-signature-box"
                      :class="{ 'signed': serviceForm.customerSign }"
                      :disabled="serviceForm.status == 'awaiting approval' && (serviceForm.formStatus != 'Signing' || serviceForm.formStatus != 'Signed and Sent')"
                    >
                      <div class="form-signature-content">
                        <span
                          v-if="!serviceForm.customerSign"
                          class="form-signature-text"
                        >
                          Sign here
                        </span>
                        <b-img
                          v-else
                          :src="serviceForm.customerSign"
                          alt="authorized-sign"
                        />
                      </div>
                    </div>
                    <div class="signature-date-block ps-signature-date-block">
                      <p>Name 姓名: {{ serviceForm.customerName }}</p>
                      <p v-if="serviceForm.customerSignDate">
                        Date 日期: {{ dateFormatWithTime(serviceForm.customerSignDate) }}
                      </p>
                      <p v-else>
                        Date 日期: {{ serviceForm.customerSignDate || '-' }}
                      </p>
                    </div>
                  </b-col>
                </b-row>
              </div>

              <div class="border mt-4 ps-bottom-box">
                <h5 class="border-bottom text-center text-uppercase">
                  For office use 公务用栏
                </h5>
                <b-row>
                  <b-col
                    md="6"
                    class="px-2 py-2 ps-authorized-signature"
                  >
                    <p>Service Staff 服务职员:</p>
                    <div
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-modal.staff-sign-modal
                      class="form-signature-box"
                      :class="{ 'signed': serviceForm.staffSign }"
                      :disabled="serviceForm.status == 'awaiting approval' && (serviceForm.formStatus != 'Signing' || serviceForm.formStatus != 'Signed and Sent')"
                    >
                      <div class="form-signature-content">
                        <span
                          v-if="!serviceForm.staffSign"
                          class="form-signature-text"
                        >
                          Sign here
                        </span>
                        <b-img
                          v-else
                          :src="serviceForm.staffSign"
                          alt="authorized-sign"
                        />
                      </div>
                    </div>
                    <div class="signature-date-block ps-signature-date-block">
                      <p>Name 姓名: {{ serviceForm.signedByStaffName }}</p>
                      <p v-if="serviceForm.staffSignDate">
                        Date 日期: {{ dateFormatWithTime(serviceForm.staffSignDate) }}
                      </p>
                      <p v-else>
                        Date 日期:
                      </p>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-card-text>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <!-- customer signature modal -->
    <b-modal
      id="customer-sign-modal"
      ref="customer-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Customer Signature"
      @ok="closeCustomerSignModal"
      @cancel="closeCustomerSignModal"
    >
      <template #modal-title>
        <span>
          Signature
        </span>
        <br>
        <span class="weight-400 fs-14">Purchaser / Authorized Representative Signatory & Date</span>
      </template>
      <div class="">
        <VueSignaturePad
          ref="customerSignaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="customerPadOptions"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearCustomerSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveCustomerSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>

    <!-- staff signature modal -->
    <b-modal
      id="staff-sign-modal"
      ref="staff-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="staff Signature"
      @ok="closeStaffSignModal"
      @cancel="closeStaffSignModal"
    >
      <template #modal-title>
        <span>
          Signature
        </span>
        <br>
        <span class="weight-400 fs-14">Service Staff</span>
      </template>
      <div class="">
        <VueSignaturePad
          ref="staffSignaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="staffPadOptions"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          class="mr-auto ml-0 pl-0"
          @click="clearStaffSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveStaffSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- submit signed form -->
    <b-modal
      id="submit-form-modal"
      ref="submit-form-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Submit this signed form?"
      @ok="submitSignedForm"
      @cancel="closeSubmitFormModal"
    >
      <div class="">
        <div>
          If the information in <strong>Service Form</strong> is accurate, submitting this form will generate a PDF copy to be sent out to customer.
        </div>
        <div class="mt-1 mb-50">
          Please confirm the PDF will be send via:
        </div>
        <b-tabs
          pills
          class="d-form-tab booking__content"
        >
          <b-tab
            :active="tabActive == 'email'"
            class="tab-content-wrapper"
            @click="tabActive = 'email'"
          >
            <template #title>
              <span class="">Email Only</span>
            </template>
            <b-form-group
              label="Email Address"
              label-for="customer-email"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                vid="customer-email"
                rules="required"
              >
                <b-form-input
                  id="customer-email"
                  v-model="customerEmailVal"
                  placeholder="jlam@gmail.com"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <quill-editor
                  v-model="customerMessageValEmail"
                  :options="editorOptionEmail"
                >
                  <div
                    id="customer-email-message"
                    slot="toolbar"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold">
                      Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      Underline
                    </button>
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-align">
                      <option selected="selected" />
                      <option value="center" />
                      <option value="right" />
                      <option value="justify" />
                    </select>
                  </div>
                </quill-editor>
              </validation-provider>
            </b-form-group>
          </b-tab>
          <b-tab
            :active="tabActive == 'phone'"
            class="tab-content-wrapper"
            @click="tabActive = 'phone'"
          >
            <template #title>
              <span class="">SMS Only</span>
            </template>
            <b-form-group
              label="Mobile Number"
              label-for="customer-mobile"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                vid="customer-mobile"
                rules="required"
              >
                <b-form-input
                  id="customer-mobile"
                  v-model="customerMobileVal"
                  placeholder="(65) 98784248"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <b-form-textarea
                  v-model="customerMessageValPhone"
                  placeholder="Enter"
                  rows="3"
                  name="customerMessageValPhone"
                />
              </validation-provider>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          No, go back
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="(tabActive == 'email' && !customerEmailVal) || (tabActive == 'phone' && !customerMobileVal)"
          @click="ok()"
        >
          <feather-icon
            icon="ArrowRightIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Yes, Proceed</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="resend-form-modal"
      ref="resend-form-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Resend this form?"
      @ok="resendSignedForm"
      @cancel="closeResendFormModal"
    >
      <div class="">
        <div>
          If the information in <strong>Service Form</strong> is accurate, submitting this form will generate a PDF copy to be sent out to customer.
        </div>
        <div class="mt-1 mb-50">
          Please confirm the PDF will be send via:
        </div>
        <b-tabs
          pills
          class="d-form-tab submit-d-form booking__content"
        >
          <b-tab
            :active="tabActive == 'email'"
            class="tab-content-wrapper"
            @click="tabActive = 'email'"
          >
            <template #title>
              <span class="">Email Only</span>
            </template>
            <b-form-group
              label="Email Address"
              label-for="customer-email"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                vid="customer-email"
                rules="required"
              >
                <b-form-input
                  id="customer-email"
                  v-model="customerEmailVal"
                  placeholder="jlam@gmail.com"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <quill-editor
                  v-model="customerMessageValEmail"
                  :options="editorOptionEmail"
                >
                  <div
                    id="customer-email-message"
                    slot="toolbar"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold">
                      Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      Underline
                    </button>
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-align">
                      <option selected="selected" />
                      <option value="center" />
                      <option value="right" />
                      <option value="justify" />
                    </select>
                  </div>
                </quill-editor>
              </validation-provider>
            </b-form-group>
          </b-tab>
          <b-tab
            :active="tabActive == 'phone'"
            class="tab-content-wrapper"
            @click="tabActive = 'phone'"
          >
            <template #title>
              <span class="">SMS Only</span>
            </template>
            <b-form-group
              label="Mobile Number"
              label-for="customer-mobile"
              class="mb-0"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                vid="customer-mobile"
                rules="required"
              >
                <b-form-input
                  id="customer-mobile"
                  v-model="customerMobileVal"
                  placeholder="(65) 98784248"
                  class="mb-0"
                  :state="(errors.length > 0) ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Message to customer (optional)"
              label-for="customer-message"
              class="mt-75"
            >
              <validation-provider
                name="customer-message"
                vid="customer-message"
                rules=""
              >
                <b-form-textarea
                  v-model="customerMessageValPhone"
                  placeholder="Enter"
                  rows="3"
                  name="customerMessageValPhone"
                />
              </validation-provider>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          No, back to Form
        </b-button>
        <b-button
          variant="success"
          class="custom-icon"
          :disabled="(tabActive == 'email' && !customerEmailVal) || (tabActive == 'phone' && !customerMobileVal)"
          @click="ok()"
        >
          <feather-icon
            icon="ArrowRightIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Yes, Proceed</span>
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BCard, BRow, BButton, BCol, BTableSimple, BTbody, BTr, BTh, BTd, VBToggle, VBTooltip, BCardText, BImg, BContainer,
  BFormCheckbox, BFormGroup, BTab, BTabs, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import store from '@/store/index'
import { required } from '@validations'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import ToastificationContentCustom from '../ToastificationContentCustom.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BCardText,
    BImg,
    BContainer,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BTabs,
    BTab,
    BFormTextarea,
    quillEditor,
    ValidationProvider,
    AppTimeline,
    AppTimelineItem,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      serviceForm: {},
      serviceFormID: '',
      stringID: '',
      status: 'pending',
      statusValidation: false,
      statusError: 'Valid status is required',
      dutyOfficer: '',
      dutyOfficerValidation: false,
      showCustomFormSection: false,
      dutyOfficerError: 'Valid duty officer is required',
      dutyOfficerOptions: [],
      serviceFormActivityLogs: [],
      statusDataOptions: [
        { title: 'Completed', code: 'completed' },
        { title: 'Pending', code: 'pending' },
      ],
      requestTypeOptions: [],
      assigneeOptions: [],
      smsText: '',
      agentContact: '',
      customerContact: '',
      smsTextValidation: false,
      smsTextError: '',
      description: '',
      attachmentKey: '',
      shallShowEmailComposeModal: false,
      required,
      emailToOptions: [],
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
      currentTabTitle: 'Service Form',
      activeTab: 0,
      checked: true,
      serviceRequests: [],
      serviceFormView: {},
      venueField: '',
      titleFields: '',
      specialWorkflows: [],
      specialSDWorkflows: [],
      specialServiceDescription: [],
      groupedCategory: [],
      tabActive: 'email',
      customerEmailVal: '',
      customerMobileVal: '',
      customerMessageValEmail: '',
      customerMessageValPhone: '',
      editorOptionPhone: {
        modules: {
          toolbar: '#customer-sms-message',
        },
        placeholder: 'Compose message',
      },
      editorOptionEmail: {
        modules: {
          toolbar: '#customer-email-message',
        },
        placeholder: 'Compose message',
      },
      customerPadOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.customerSignaturePad.resizeCanvas() },
      },
      staffPadOptions: {
        penColor: '#000000',
        onBegin: () => { this.$refs.staffSignaturePad.resizeCanvas() },
      },
    }
  },
  mounted() {
    this.fetchServiceForm()
  },
  methods: {
    fetchServiceForm() {
      this.$http.get(`operation/service-forms/${this.$route.params.id}/show/form`)
        .then(response => {
          this.serviceForm = response.data.data || {}
          this.serviceRequests = response.data.serviceRequests || {}
          this.serviceFormView = response.data.serviceFormView || {}
          this.venueField = response.data.venueField ?? ''
          this.titleFields = response.data.titleFields ?? ''
          this.specialWorkflows = response.data.specialWorkflows.split(',')
          this.specialSDWorkflows = response.data.specialSDWorkflows.split(',')
          this.specialServiceDescription = response.data.specialServiceDescription.split(',')
          document.title = `${response.data.data.stringID} - ${response.data.data.customerName} | ${process.env.VUE_APP_APP_NAME || 'Nirvana Memorial Garden'}`

          this.setData()

          // let fieldCount = 0
          // if (this.serviceForm.serviceDescription.length) {
          //   this.serviceForm.serviceDescription.forEach(arrayItem => {
          //     if (!['file', 'title-and-text'].includes(arrayItem.type)) {
          //       // eslint-disable-next-line no-plusplus
          //       fieldCount++
          //     }
          //   })
          // }

          // if (fieldCount > 0) {
          //   this.showCustomFormSection = true
          // }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    changeTab(index) {
      this.activeTab = index
    },
    formName(id) {
      const form = this.formOptions.find(o => o._id === id)
      if (form) {
        return form.name
      }

      return ''
    },
    resolveFormStatusVariant(status) {
      if (status === 'Signed and Sent') return 'success'
      if (status === 'Signing') return 'warning'
      return 'primary'
    },
    showModalOrWarning() {
      if (this.serviceForm.status === 'awaiting approval') {
        this.$swal({
          title: 'Warning: Service Request is ‘Awaiting Approval’ and you need to settle this first before you can change the Service Form status',
          html: '',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          imageWidth: 80,
          imageHeight: 80,
          imageAlt: 'Custom Icon',
          showCancelButton: false,
          confirmButtonText: 'Got it',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
          width: 485,
        })
      } else {
        this.$root.$emit('bv::show::modal', 'serviceForm-all-modal-update-status', '')
      }
    },
    closeCustomerSmsModal() {
      this.$root.$emit('bv::hide::modal', 'modal-send-sms-customer', '')
    },
    closeAgentSmsModal() {
      this.$root.$emit('bv::hide::modal', 'modal-send-sms-agent', '')
    },
    clearCustomerSign() {
      this.$refs.customerSignaturePad.clearSignature()
    },
    saveCustomerSign() {
      const { data } = this.$refs.customerSignaturePad.saveSignature()
      if (data) {
        this.$root.$emit('bv::hide::modal', 'customer-sign-modal', '')
        const formData = new FormData()
        const customerSignObj = {
          key: 'customerSignSrc',
          value: data,
        }
        let allSigned = false
        if (this.serviceForm.staffSign) {
          allSigned = true
        }
        formData.append('allSigned', allSigned)
        formData.append('signature', JSON.stringify(customerSignObj))
        this.$http.post(`operation/service-forms/${this.$route.params.id}/store/sign/customer`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(async response => {
            if (response.data.data) {
              this.serviceForm = response.data.data || {}
              if (allSigned) {
                this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
              }
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || '',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    closeDescriptionModal() {
      this.$root.$emit('bv::hide::modal', 'modal-attachment-description', '')
    },
    closeCustomerSignModal() {
      this.$root.$emit('bv::hide::modal', 'customer-sign-modal', '')
    },
    openCustomerSignModal() {
      this.$root.$emit('bv::show::modal', 'customer-sign-modal', '')
    },
    openStaffSignModal() {
      this.$root.$emit('bv::show::modal', 'staff-sign-modal', '')
    },
    closeStaffSignModal() {
      this.$root.$emit('bv::hide::modal', 'staff-sign-modal', '')
    },
    clearStaffSign() {
      this.$refs.staffSignaturePad.clearSignature()
    },
    saveStaffSign() {
      const { data } = this.$refs.staffSignaturePad.saveSignature()
      if (data) {
        this.$root.$emit('bv::hide::modal', 'staff-sign-modal', '')
        const formData = new FormData()
        const staffSignObj = {
          key: 'staffSignSrc',
          value: data,
        }
        let allSigned = false
        if (this.serviceForm.customerSign) {
          allSigned = true
        }
        formData.append('allSigned', allSigned)
        formData.append('signature', JSON.stringify(staffSignObj))
        this.$http.post(`operation/service-forms/${this.$route.params.id}/store/sign/staff`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(async response => {
            if (response.data.data) {
              this.serviceForm = response.data.data || {}
              if (allSigned) {
                this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
              }
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || '',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    closeSubmitFormModal() {
      this.$root.$emit('bv::hide::modal', 'submit-form-modal', '')
    },
    submitSignedForm() {
      const formData = new FormData()
      formData.append('customerEmail', this.customerEmailVal)
      formData.append('customerContact', this.customerPhoneVal)
      if (this.tabActive === 'email') {
        formData.append('message', this.customerMessageValEmail)
      } else {
        formData.append('message', this.customerMessageValPhone)
      }
      formData.append('sendOptionType', this.tabActive)
      formData.append('formName', this.serviceFormView.name)
      formData.append('formStatus', 'Signed and Sent')
      formData.append('url', `${window.location.origin}/operations/service-forms/${this.$route.params.id}/form/pdf-view`)

      this.$http.post(`operation/service-forms/${this.$route.params.id}/form/generate-pdf`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(async response => {
          if (response.data.data) {
            this.serviceForm = response.data.data || {}
            this.$root.$emit('bv::hide::modal', 'submit-form-modal', '')
            this.$toast({
              component: ToastificationContentCustom,
              props: {
                title: 'PDF is ready!',
                text: response.data.message,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    closeResendFormModal() {
      this.$root.$emit('bv::hide::modal', 'resend-form-modal', '')
    },
    startSignProcess() {
      const finalStatus = 'Signing'
      const formData = new FormData()
      formData.append('status', finalStatus)
      this.$http.post(`operation/service-forms/${this.$route.params.id}/update/sign-status`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(async response => {
          if (response.data.data) {
            this.serviceForm.formStatus = finalStatus
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || '',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    resendSignedFormModal() {
      if (this.serviceForm.pdfs.length) {
        this.$root.$emit('bv::show::modal', 'resend-form-modal', '')
      } else {
        this.$root.$emit('bv::show::modal', 'submit-form-modal', '')
      }
    },
    resendSignedForm() {
      const formData = new FormData()
      formData.append('customerEmail', this.customerEmailVal)
      formData.append('customerContact', this.customerMobileVal)
      if (this.tabActive === 'email') {
        formData.append('message', this.customerMessageValEmail)
      } else {
        formData.append('message', this.customerMessageValPhone)
      }
      formData.append('formName', this.serviceFormView.name)
      formData.append('sendOptionType', this.tabActive)
      formData.append('url', `${window.location.origin}/operations/service-requests/form/${this.serviceForm._id}/detail`)
      this.$http.post(`operation/service-forms/${this.$route.params.id}/form/resend-pdf`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.$toast({
            component: ToastificationContentCustom,
            props: {
              title: 'PDF is ready!',
              text: response.data.message,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    sendSms(type) {
      let phone = ''
      if (type === 'agent') {
        phone = this.agentContact
      } else {
        phone = this.customerContact
      }

      this.$http
        .post('operation/service-forms/send-sms', { phone, message: this.smsText, serviceForm: this.$route.params.id })
        .then(response => {
          this.serviceForm = response.data.data || {}
          this.setData()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          if (type === 'agent') {
            this.closeAgentSmsModal()
          } else {
            this.closeCustomerSmsModal()
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    updateDescription(key) {
      this.attachmentKey = key
      this.description = this.serviceForm.attachments[key].description
      this.$root.$emit('bv::show::modal', 'modal-attachment-description', '')
    },
    removeAttachment(url) {
      this.$http
        .post('operation/service-forms/remove-attachment', { url, serviceForm: this.$route.params.id })
        .then(response => {
          this.serviceForm = response.data.data || {}
          this.setData()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getVariant(key) {
      if (key % 4 === 0) return 'info'
      if (key % 4 === 1) return 'warning'
      if (key % 4 === 2) return 'danger'
      if (key % 4 === 3) return 'purple'
      return 'primary'
    },
    saveDescription() {
      const url = this.serviceForm.attachments[this.attachmentKey].data
      this.$http
        .post('operation/service-forms/save-description', { url, description: this.description, serviceForm: this.$route.params.id })
        .then(response => {
          this.serviceForm = response.data.data || {}
          this.setData()
          this.closeDescriptionModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    setData() {
      this.serviceFormID = this.serviceForm._id
      this.status = this.serviceForm.status
      this.stringID = this.serviceForm.stringID
      this.dutyOfficer = this.serviceForm.dutyOfficer
      this.customerContact = this.serviceForm.customerContact
      this.customerMobileVal = this.serviceForm.customerContact
      this.customerEmailVal = this.serviceForm.customerEmail
      this.agentContact = this.serviceForm.agentContact
      const groupByCategory = this.serviceRequests.reduce((group, request) => {
        const category = request.workFlow.category._id
        // eslint-disable-next-line no-param-reassign
        group[category] = group[category] ?? []
        group[category].push(request)
        return group
      }, {})

      this.groupedCategory = groupByCategory
    },
    dutyOfficerSubmitForm() {
      this.$refs.serviceFormDutyOfficerEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('dutyOfficer', this.dutyOfficer)

          this.$http.patch(`operation/service-forms/${this.serviceFormID}/duty-officer/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.serviceForm.dutyOfficer = response.data.data.dutyOfficer || {}
              this.serviceForm.activityLog = response.data.data.activityLog || []
              this.setData()
              // this.$nextTick(() => {
              //   this.$refs['update-status-modal'].toggle('#toggle-btn')
              // })
              this.$swal({
                title: 'Duty Officer Updated!',
                html: `Duty Officer for <strong>${this.stringID}</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'dutyOfficer') {
                    this.dutyOfficerError = validationError.msg
                    this.dutyOfficerValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },

    submitForm() {
      this.$refs.allServiceFormStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.status)

          this.$http.patch(`operation/service-forms/${this.serviceFormID}/status/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.serviceForm.status = response.data.data.status || 'pending'
              this.serviceForm.activityLog = response.data.data.activityLog || []
              this.setData()
              this.$swal({
                title: 'Status Updated!',
                html: `The status for <strong>${this.stringID}</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    resolveOptionValue(val) {
      if (val === 'file') return 'Image'
      if (val === 'title-and-text') return 'Title and Text'
      if (val === 'short-answer') return 'Short Answer Field'
      if (val === 'long-answer') return 'Long Answer Field'
      if (val === 'multi-select') return 'Selection List Field (Add-Ons)'
      if (val === 'single-select') return 'Single Checkbox Field'
      return '-'
    },
  },
}
</script>

<style>
.timeline-variant-info .timeline-item-point {
  background-color: #00cfe8 !important;
}
.timeline-variant-purple .timeline-item-point {
  background-color: #7367F0 !important;
}
</style>
<style lang="scss" scoped>
  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }
  .inlineElement {
    display: flex;
  }
  .clickableText {
    cursor: pointer;
    color: #104D9D;
  }
  ul.nav.wrap-border.save-nav {
    position: inherit;
    left: 0;
}
.service-request-card {
    margin: 40px 0 40px !important;
}
.sd-step-wrapper {
    padding: 40px 0;
}
.sd-step-title{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #000000;
}
.sd-step-opacity {
    opacity: 0.3;
    pointer-events: none;
}
.sd-full-opacity {
  opacity: 1;
  cursor: pointer;
}
.sd-service-single-block{
    background: #E0E8F2;
    border-radius: 6px;
    padding: 20px;
    margin: 10px 0;
}
.sd-option-button{
    display: block;
}
.sd-option-button .btn-outline-primary:hover:not(.disabled):not(:disabled) {
    background-color: #104D9D;
    color: #FFFFFF;
}
.sd-option-button .btn-outline-primary:not(:disabled):not(.disabled).active, .sd-option-button .btn-outline-primary:not(:disabled):not(.disabled):focus {
      background-color: #104D9D;
      color: #FFFFFF;
}

.sd-service-description-title{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 0;
}

.sd-single-service-description{
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    background: #F5F8FB;
    border-radius: 6px;
    margin: 5px 0;
    p{
        margin-bottom: 0;
        margin: auto 0;
    }
    .increment-decrement-block{
      margin: 0;
    }
}
.ps-avatar-bg{
    background: rgba(255, 255, 255, 0.3);
}

.ps-cd-header-text-section{
      margin-left: 20px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      margin-top: -5px;
      p{
        margin-bottom: 0;
        color: #FFFFFF;
        font-size: 12px;
        line-height: 23px;
        letter-spacing: 0.6px;
        text-transform: uppercase;
      }
      h5{
        margin-bottom: 0;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
      }
}
.ps-main-content-wrapper{
      background: #9FB8D8;
      padding: 15px;
      position: relative;
}
.ps-tooltip-card{
    position: absolute;
    left: -33%;
    bottom: 72.7%;
}
.ps-tooltip-card .card .card-body{
    padding: 0 !important;
}

.ps-tooltip-card .card .card-body .card-text{
    padding: 17px;
    p{
    margin-bottom: 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    }
}
.ps-tooltip-card .card .card-body .card-text .ps-tooltip-button{
      background: rgba(255, 159, 67, 0.12) !important;
      border: none !important;
      color: #FF9F43 !important;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      text-align: center;
      letter-spacing: 0.366667px;
      padding: 8px 19px;
      margin-top: 20px !important;
    }
.ps-tooltip-card .card .card-body .card-header{
    padding: 7px 16px;
    p{
      margin-bottom: 0;
      font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #FFFFFF;
    }
}
.ps-main-content-wrapper .card .card-body{
    padding: 20px;
}
.ps-simple-table .table th, .ps-simple-table .table td {
    padding: 3px 0 !important;
}

.ps-service-type-block{
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #CBCBCB;
  .ps-service-type-title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    background: #4B4B4B;
    padding: 10px 20px;
    margin: 0;
  }
  .ps-service-type-name{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    background: #D2DCEA;
    padding: 6px 20px;
  }
}

.ps-service-des-block{
    padding: 10px 20px;
}
.ps-service-des-checkbox-block{
    p{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        margin: 0;
    }
  }

.ps-service-des-content-block{
    padding: 0px 0px 0px 28px;
}

.ps-service-des-bg-block{
    background: rgba(186, 191, 199, 0.12);
    padding: 10px 20px;
    margin-bottom: 10px;
}
.ps-notice-left-section{
      padding: 20px;
      p{
        font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      margin-bottom: 15px;
      }
      .ps-notice-field-value{
        color: #104D9D !important;
        border-bottom: 1px solid #000000;
      }
}
.ps-notice-right-section{
      padding: 10px;
      font-family: 'Montserrat';
      font-style: normal;
      h5{
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: #000000;
      }
      ul{
        padding-left: 20px;
        list-style: disc;
      }
      ul > li{
          color: #000000;
          font-size: 14px;
          line-height: 18px;
          padding: 3px 0;
      }

}
.ps-authorized-signature{
    font-family: 'Montserrat';
    font-style: normal;
    p{
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      margin-bottom: 10px;
      color: #000000;
    }
}
  .ps-authorized-signature .ps-signature-date-block p{
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      margin-bottom: 0;
      color: #000000;
  }
  .ps-bottom-box{
    h5{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      padding: 8px;
    }
  }
.page-content hr {
  margin: 20px 0;
  border-color: #D2DCEA;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>
